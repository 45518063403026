import {Component, Input, OnInit} from '@angular/core';
import {Availability, FilterService} from '../filter.service';
import {MapComponent} from '../../map/map.component';

@Component({
  selector: 'app-available',
  templateUrl: './available.component.html'
})
export class AvailableComponent implements OnInit {
  @Input() map: MapComponent;
  constructor(private filterService: FilterService) {
  }

  available = true;
  unavailable = true;

  Availability = Availability;

  selectAvailability(availability: Availability) {
    if (availability === Availability.available) {
      this.available = !this.available;
    } else if (availability === Availability.unavailable) {
      this.unavailable = !this.unavailable;
    }
    const values = [];
    if (this.available) {
      values.push(Availability[Availability.available]);
      values.push(Availability[Availability.unknown]);
    }
    if (this.unavailable) {
      values.push(Availability[Availability.unavailable]);
    }
    this.filterService.addFilter({key: 'availability', operators: ['IN'], values: values});
    this.map.reload();
  }

  ngOnInit() {
  }

}
