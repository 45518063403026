import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material';
import {DetailWindowComponent} from '../detail-window/detail-window.component';
import {PriceWindowComponent} from '../price-window/price-window.component';
import {SettingsService} from '../../service/settings/settings.service';

@Component({
  selector: 'app-info-window',
  templateUrl: './info-window.component.html'
})
export class InfoWindowComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public settingsService: SettingsService) {
  }

  details() {
    const ref = this.dialog.open(DetailWindowComponent, {
      autoFocus: false, data: this.data
    });
  }

  prices() {
    const ref = this.dialog.open(PriceWindowComponent, {
      autoFocus: false, data: this.data
    });
  }
}
