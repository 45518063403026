import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  public settings: Settings;

  public settingsLoaded: EventEmitter<Settings> = new EventEmitter();

  constructor(private http: HttpClient) {
  }

  loadSettings(brand: string) {
    if (!brand) {
      brand = 'default';
    }
    if (!this.settings || this.settings.brand !== brand) {
      this.http.get('/assets/brands/' + brand + '/' + 'settings.json')
        .subscribe((data: Settings) => {
            this.settings = {
              brand: brand,
              title: data['title'],
              language: data['language'],
              zoom: data['zoom'],
              centerLat: data['centerLat'],
              centerLng: data['centerLng'],
              logoExtension: data['logoExtension'],
              layer: data['layer'],
              detailsZoom: data['detailsZoom'],
              showId: data['showId'] ? data['showId'] : false,
              endpoint: data['endpoint'] ? data['endpoint'] : null,
            };
            
            this.settingsLoaded.emit(this.settings);
          }, error => {
            // try with default
            this.loadSettings('default');
          }
        );
    }
  }
}

export interface Settings {
  title: string;
  language: string;
  brand: string;
  zoom: number;
  centerLat: number;
  centerLng: number;
  logoExtension?: string;
  layer: string;
  detailsZoom: number;
  showId?: boolean;
  endpoint?: string;
}
