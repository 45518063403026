import {ChangeDetectorRef, Injectable} from '@angular/core';
import {Map, LatLngBounds} from 'leaflet';
import {FilterService} from '../filter/filter.service';
import {HttpClient} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {InfoWindowComponent} from '../info-window/info-window.component';
import {MatDialog} from '@angular/material';
import {Settings} from '../../service/settings/settings.service';
declare let L;

@Injectable({
  providedIn: 'root'
})
export class MapService {

  constructor(
      private filterService: FilterService,
      private http: HttpClient,
      public translate: TranslateService,
      public dialog: MatDialog
    ) {
    this.mapUrl = null;
  }

  public static WMS_LAYER = 'eco:rta_and_clusters';
  private mapUrl: string;

  request: Subscription;

  public widget = false;
  public brand = false;
  public lat: number;
  public lng: number;
  public zoom: number;
  public detailsZoom = 14;
  public map: Map;
  public box: LatLngBounds;
  public loading = false;
  public location: Location;

  public ecoLayer = new L.tileLayer.wms('/wms',
      {
        layers: MapService.WMS_LAYER,
        transparent: true,
        format: 'image/png',
      });

  public changeDetector: ChangeDetectorRef;

  setEndpoint(settings: Settings) {
    this.mapUrl = (settings.endpoint) ? settings.endpoint : '/api/map/location/';
  }
  getFeatureInfo(event) {
    if (this.map.getZoom() > this.detailsZoom) {
      this.loading = true;
      if (event) {
        const url = this.getFeatureInfoUrl(event.latlng);
        this.http.get(url)
          .subscribe(result => this.getFeatureInfoResult(result));
      }
    } else {
      this.loading = false;
    }
  }
  getFeatureInfoUrl(latlng) {

    // Construct a GetFeatureInfo request URL given a point
    const point = this.map.latLngToContainerPoint(latlng);
    const size = this.map.getSize(),

      params = {
        request: 'GetFeatureInfo',
        service: 'WMS',
        srs: 'EPSG:4326',
        version: '1.1.1',
        info_format: 'application/json',
        bbox: this.map.getBounds().toBBoxString(),
        height: size.y,
        width: size.x,
        layers: MapService.WMS_LAYER,
        query_layers: MapService.WMS_LAYER,
      };

      params[params.version === '1.3.0' ? 'i' : 'x'] = Math.floor(point.x);
      params[params.version === '1.3.0' ? 'j' : 'y'] = Math.floor(point.y);

      return '/wms'
        + L.Util.getParamString(params, '/wms', true);
  }

  getFeatureInfoResult(data) {
    if (data.features[0] && data.features[0].properties) {
      const external_reference = data.features[0].properties.external_reference;
      this.http.get(this.mapUrl + external_reference)
        .subscribe(result => this.loadLocation(result));
    } else {
      this.loading = false;
    }
    this.changeDetector.detectChanges();
  }

  loadLocation(result) {
    this.changeDetector.detectChanges();
    if (result.data && result.data.evses) {
      const chargepoint: Location = {
        groupedConnectors: [],
        pricesPerConnectorType: [],
        publicaccess: result.data.access_type,
        support: {
          tel: result.data.support_phone_number
        },
        operator: result.data.operator ? result.data.operator.name : null,
        suboperator: result.data.suboperator ? result.data.suboperator.name : null,
        geographic: {
          city: result.data.city,
          country: result.data.country,
          postalCode: result.data.postal_code,
          street: result.data.address
        },
        id: String(result.data.id),
        hasTariffs: false
      };
      // connectors
      for (let i = 0; i < result.data.evses.length; i++) {
        const spot = result.data.evses[i];
        for (let j = 0; j < spot.connectors.length; j++) {
          const connector = spot.connectors[j];
          const standard = String(connector.standard);
          const label = this.determineLabel(standard);
          let connGroup = chargepoint.groupedConnectors.find(conn =>
            conn.standard === label
            && conn.power === Number(connector.max_power)
            && conn.status === result.data.evses[i].status);
          if (!connGroup) {
            connGroup = {
              standard: label,
              status: spot.status,
              power: Number(connector.max_power),
              count: 1
            };
            chargepoint.groupedConnectors.push(connGroup);
          } else {
            connGroup.count = connGroup.count + 1;
          }

          // tariffs
          if (connector.tariffs && connector.tariffs.length > 0) {
            chargepoint.hasTariffs = true;
            if (chargepoint.pricesPerConnectorType.filter(
              conn => (conn.power === connector.max_power && conn.standard === label)).length === 0) {
              const connType = {
                standard: label,
                power: connector.max_power,
                tariffs: []
              };
              for (let k = 0; k < connector.tariffs.length; k++) {
                const tariff = connector.tariffs[k];

                let typeLabel;
                if (tariff.type === 'ENERGY') {
                  typeLabel = 'Tarief per kWh';
                } else if (tariff.type === 'FLAT') {
                  typeLabel = 'Start tarief per sessie';
                } else if (tariff.type === 'PARKING_TIME') {
                  typeLabel = 'Parkeertarief per uur.';
                } else if (tariff.type === 'TIME') {
                  typeLabel = 'Tarief per uur.';
                }
                let price = Number(tariff.price);
                if (tariff.vat !== '') {
                  const vat = Number(tariff.vat);
                  price = price + (price * (vat / 100));
                }
                if (connType.tariffs.filter(i => i.type === typeLabel).length === 0) {
                  connType.tariffs.push(
                    {
                      currency: tariff.currency === 'EUR' ? '€' : tariff.currency,
                      type: typeLabel,
                      price: price
                    });
                }
              }
              if (connType.tariffs.length > 0) {
                chargepoint.pricesPerConnectorType.push(connType);
              }
            }
          }
        }
      }
      this.info(chargepoint);
    } else {
      this.error(this.translate.instant('NO_ACCESS'));
    }
    this.loading = false;
    this.changeDetector.detectChanges();
  }

  private determineLabel(standard) {
    let label = '';
    if (standard === 'IEC_62196_T2') {
      label = this.translate.instant('CONNECTOR.TYPE_2');
    } else if (standard.includes('IEC_62196_T') && standard.includes('COMBO')) {
      label = this.translate.instant('CONNECTOR.COMBO');
    } else if (standard === 'CHADEMO') {
      label = this.translate.instant('CONNECTOR.CHADEMO');
    } else if (standard.includes('DOMESTIC')) {
      label = this.translate.instant('CONNECTOR.DOMESTIC');
    } else if (standard.includes('IEC_62196_T1') || standard.includes('IEC 62196 Type 1 SAE J1772')) {
      label = this.translate.instant('CONNECTOR.TYPE_1');
    } else if (standard.includes('EC 62196 Type 3A Scame')) {
      label = this.translate.instant('CONNECTOR.TYPE_3');
    } else if (standard.includes('TESLA')) {
      label = this.translate.instant('CONNECTOR.TESLA');
    } else {
      label = standard;
    }
    return label;
  }


  info(chargepoint) {
    const ref = this.dialog.open(InfoWindowComponent, {
      autoFocus: false,
      data: {location: chargepoint}
    });
  }

  error(message) {
    const ref = this.dialog.open(InfoWindowComponent, {
      autoFocus: false,
      data: {error: message}
    });
  }
}

export interface Location {
  id: string;
  publicaccess: string;
  groupedConnectors: GroupedConnector[];
  pricesPerConnectorType: ConnectorType[];
  support?: Support;
  operator: string;
  suboperator: string;
  geographic: Geographic;
  hasTariffs: boolean;
}

export interface Support {
  tel?: string;
  email?: string;
}

export interface GroupedConnector {
  status: string;
  standard: string;
  power: number;
  count: number;
}

export interface ConnectorType {
  standard: string;
  power: number;
  tariffs: Tariff[];
}

export interface Tariff {
  currency: string;
  type: string;
  price: number;
}

export interface Geographic {
  street: string;
  postalCode: string;
  city: string;
  country: string;
}

