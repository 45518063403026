import {ChangeDetectionStrategy, ChangeDetectorRef, Component} from '@angular/core';
import {OnInit} from '@angular/core';
import {OpenStreetMapProvider, GeoSearchControl} from 'leaflet-geosearch';
import {MapService} from './map.service';

declare let L;
import '../../../../node_modules/leaflet.locatecontrol/dist/L.Control.Locate.min';
import {FilterService} from '../filter/filter.service';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapComponent implements OnInit {


  public center;
  public layers = [];
  public provider = new OpenStreetMapProvider();
  public searchControl;
  public locationControl;



  public options = {
    layers:
      [
        new L.TileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png',
          {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>' +
              ' contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
            subdomains: 'abcd',
          }),
        this.mapService.ecoLayer
      ],
    zoomControl: false,
  };


  constructor(
    public mapService: MapService,
    private changeDetector: ChangeDetectorRef,
    private filterService: FilterService) {
  }

  ngOnInit() {
  }

  onMapReady(map: L.Map) {
    this.mapService.map = map;
    this.mapService.changeDetector = this.changeDetector;
    map.addControl(new L.Control.Zoom({position: 'bottomright'}));
    this.searchControl = new GeoSearchControl({
      provider: this.provider,
      position: 'topright',
      showMarker: false
    });

    this.mapService.map.addControl(this.searchControl);
    this.locationControl = L.control.locate({
      position: 'topright', showPopup : false,
      strings: {
        title: 'Show me where I am'
      }
    });
    this.changeDetector.detectChanges();
    if (!this.mapService.widget && !this.mapService.brand) {
      this.toCurrentLocation();
    } else {
      map.flyTo([this.mapService.lat, this.mapService.lng], this.mapService.zoom, {animate: false});
    }
  }

  reload() {
    if (this.mapService.map.getZoom() > this.mapService.detailsZoom) {
      const filter = this.filterService.toCQLFilter();
      this.mapService.ecoLayer.setParams({cql_filter: filter !== '' ? filter + ';INCLUDE' : 'INCLUDE;INCLUDE' });
    } else {
      this.mapService.ecoLayer.setParams({cql_filter: 'INCLUDE;INCLUDE'});
    }
    this.changeDetector.detectChanges();
  }

  onBoundsChange(event: any) {

    if (!this.mapService.loading) {
      this.changeDetector.detectChanges();
    }
  }


  onMapClick(event: any) {
    this.mapService.getFeatureInfo(event);
  }


  private toCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        // only add this control when geolocation works
        this.mapService.map.addControl(this.locationControl);
        this.center = new L.LatLng(position.coords.latitude, position.coords.longitude);
      }, positionError => {}, {timeout: 6000});
    } else {
      this.center = new L.LatLng(52.23725801564869, 5.172180067520117);
    }
    if (this.mapService.map.getZoom() < 13) {
      this.mapService.map.setZoom(13);
    }
  }
}




