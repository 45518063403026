import {Component, Inject, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-detail-window',
  templateUrl: './detail-window.component.html'
})
export class DetailWindowComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public translate: TranslateService) {
  }

  ngOnInit() {
  }
}
