import {Component, OnInit} from '@angular/core';
import {MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {SettingsService} from '../../service/settings/settings.service';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'app-bottom-sheet-faq',
  templateUrl: 'footer.faq.html'
})
export class BottomSheetFAQComponent {
  constructor(private bottomSheetRef: MatBottomSheetRef<BottomSheetFAQComponent>) {
  }

  close() {
    this.bottomSheetRef.dismiss();
  }
}

@Component({
  selector: 'app-bottom-sheet-about-us',
  templateUrl: 'footer.about.html'
})
export class BottomSheetAboutUsComponent {
  constructor(private bottomSheetRef: MatBottomSheetRef<BottomSheetAboutUsComponent>) {
  }

  close() {
    this.bottomSheetRef.dismiss();
  }
}

@Component({
  selector: 'app-bottom-sheet-about-us',
  templateUrl: 'footer.disclaimer.html'
})
export class BottomSheetDisclaimerComponent {
  constructor(private bottomSheetRef: MatBottomSheetRef<BottomSheetDisclaimerComponent>) {
  }

  close() {
    this.bottomSheetRef.dismiss();
  }
}

@Component({
  selector: 'app-bottom-sheet-about-us',
  templateUrl: 'footer.embed.html'
})
export class BottomSheetEmbedComponent {
  constructor(private bottomSheetRef: MatBottomSheetRef<BottomSheetEmbedComponent>) {
  }

  close() {
    this.bottomSheetRef.dismiss();
  }
}

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {

  constructor(private bottomSheet: MatBottomSheet, public settings: SettingsService, public translateService: TranslateService) {
  }

  showSurvey = false;

  ngOnInit() {
  }

  openFaq() {
    this.bottomSheet.open(BottomSheetFAQComponent);
  }

  openAbout() {
    this.bottomSheet.open(BottomSheetAboutUsComponent);
  }

  openDisclaimer() {
    this.bottomSheet.open(BottomSheetDisclaimerComponent);
  }

  openEmbed() {
    this.bottomSheet.open(BottomSheetEmbedComponent);
  }
}
