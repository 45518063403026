import {Component, ViewEncapsulation, OnInit, OnDestroy} from '@angular/core';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Location} from '@angular/common';
import {Settings, SettingsService} from './service/settings/settings.service';
import {MapService} from './component/map/map.service';
import {HttpClient} from '@angular/common/http';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'app';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private location: Location,
    private settingsService: SettingsService,
    private mapService: MapService,
    private http: HttpClient,
    private titleService: Title) {
  }


  ngOnInit() {
    this.translate.addLangs(['nl', 'en', 'fr', 'de']);
    this.settingsService.settingsLoaded.subscribe((settings: Settings) => {

      this.mapService.setEndpoint(settings);
      if (!this.mapService.widget) {
        this.mapService.lat = Number(settings.centerLat);
        this.mapService.lng = Number(settings.centerLng);
        this.mapService.zoom = Number(settings.zoom);
        this.mapService.loading = false;
        this.mapService.map.flyTo([Number(settings.centerLat), Number(settings.centerLng)], Number(settings.zoom), {animate: false});
      }

      this.http.get('./assets/brands/' + settings.brand + '/' + settings.language + '.json').subscribe((data) => {
        this.translate.setTranslation(settings.language, data);
        this.translate.use(settings.language);
      });
      this.titleService.setTitle(settings.title);

      if (this.settingsService.settings.layer) {
        this.mapService.ecoLayer.setParams({layers: settings.layer});
      }
      if (this.settingsService.settings.detailsZoom) {
        this.mapService.detailsZoom = settings.detailsZoom;
      }
    });

    const domain = window.location.hostname;

    this.route.paramMap.subscribe((params: ParamMap) => {
      if (params.get('lat')) {
        this.mapService.widget = true;
        this.mapService.lat = Number(params.get('lat'));
        this.mapService.lng = Number(params.get('lng'));
        this.mapService.zoom = Number(params.get('zoom'));
      }
      this.route.queryParamMap.subscribe((params: ParamMap) => {
        let brand = params.get('brand');
        if (!brand) {
          brand = this.determineBrand(domain);
        } else {
          this.mapService.brand = true;
        }
        this.settingsService.loadSettings(brand);

      });
    });
  }

  private determineBrand(domain) {
    let brand = 'default';
    if (domain.indexOf('maps.eco-movement') !== -1) {
      brand = 'maps';
    } else if (domain.indexOf('chargepoints.eco-movement') !== -1) {
      brand = 'chargepoints';
    }
    return brand;
  }

  ngOnDestroy() {
  }

}
