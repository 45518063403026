import {Component, Input, OnInit} from '@angular/core';
import {FilterService, Power} from '../filter.service';
import {MapComponent} from '../../map/map.component';

@Component({
  selector: 'app-power',
  templateUrl: './power.component.html'
})
export class PowerComponent implements OnInit {
  @Input() map: MapComponent;
  constructor(private filterService: FilterService) { }

  fast = true;
  normal = true;

  Power = Power;

  selectPower(power: Power) {
    if (power === Power.fast) {
      this.fast = !this.fast;
    } else if (power === Power.normal) {
      this.normal = !this.normal;
    }
    const values = [];
    const operators = [];
    if (this.fast) {
      values.push(43000);
      operators.push('>=');
    }
    if (this.normal) {
      values.push(43000);
      operators.push('<');
    }
    this.filterService.addFilter({key: 'max_power', operators: operators, values: values});
    this.map.reload();

  }

  ngOnInit() {
  }

}
