import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-price-window',
  templateUrl: './price-window.component.html'
})
export class PriceWindowComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
  }


  ngOnInit() {
  }
}
