import {EventEmitter, Injectable, Input} from '@angular/core';
import {MapService} from '../map/map.service';
import {MapComponent} from '../map/map.component';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  constructor() {
    // this.filters.push({key: 'accessType', value: [Accessibility[Accessibility.public],Accessibility[Accessibility.company]].join(',')});
    // this.filters.push({
    //   key: 'available',
    //   value: [Availability[Availability.available], Availability[Availability.unavailable], Availability[Availability.unknown]].join(',')
    // });
    // this.filters.push({key: 'power', value: [Power[Power.fast], Power[Power.normal]].join(',')});
  }

  public filters: Filter[] = [];

  public addFilter(filter: Filter) {
    this.filters = this.filters.filter(f => f.key !== filter.key);
    this.filters.push(filter);
  }

  public toCQLFilter() {

    let cqlFilter = '';

    let cnt = 1;
    for (const filter of this.filters) {

      if (filter.operators.length === 1 && filter.operators[0] === 'IN') {
        cqlFilter = cqlFilter + filter.key + ' IN(' + '\'' + filter.values.join('\',\'') + '\'' + ')';
      } else {
        cqlFilter = cqlFilter + '(';

        let cnt2 = 1;
        for (const operator of filter.operators) {
          cqlFilter = cqlFilter + filter.key + ' ' + operator + ' ' + filter.values[cnt2 - 1];
          if (cnt2 !== filter.values.length) {
            cqlFilter = cqlFilter + ' OR ';
          }
          cnt2++;
        }
        cqlFilter = cqlFilter + ')';
      }
      if (cnt !== this.filters.length) {
        cqlFilter = cqlFilter + ' AND ';
      }
      cnt++;
    }
    return cqlFilter;
  }
}

export interface Filter {
  key: string;
  values: string[];
  operators: string[];
}

export enum Power {
  fast,
  normal
}

export enum Accessibility {
  Public,
  Restricted,
  Private
}

export enum Availability {
  available,
  unavailable,
  unknown
}
