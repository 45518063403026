import {Component, Input, OnInit} from '@angular/core';
import {Accessibility, FilterService} from '../filter.service';
import {MapComponent} from '../../map/map.component';

@Component({
  selector: 'app-placement',
  templateUrl: './accessibility.component.html'
})
export class AccessibilityComponent implements OnInit {
  @Input() map: MapComponent;
  constructor(private filterService: FilterService) {
  }

  public = true;
  company = true;

  Accessibility = Accessibility;

  selectAccessibility(access: Accessibility) {
    if (access === Accessibility.Public) {
      this.public = !this.public;
    } else if (access === Accessibility.Restricted) {
      this.company = !this.company;
    }

    const values = [];
    if (this.public) {
      values.push(Accessibility[Accessibility.Public]);
    }
    if (this.company) {
      values.push(Accessibility[Accessibility.Restricted]);
    }
    this.filterService.addFilter({key: 'pat', operators: ['IN'], values: values});
    this.map.reload();

  }

  ngOnInit() {
    this.filterService.addFilter(
        {key: 'pat', operators: ['IN'], values: [Accessibility[Accessibility.Public], Accessibility[Accessibility.Restricted]]});
    this.map.reload();
  }

}
