import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import {MapComponent} from '../map/map.component';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html'
})
export class MenuComponent implements OnInit {
  @Input() public map: MapComponent;

  constructor() { }

  ngOnInit() {
  }

}
