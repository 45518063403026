import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MapComponent} from './component/map/map.component';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatOptionModule} from '@angular/material/core';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSliderModule} from '@angular/material/slider';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatIconModule} from '@angular/material/icon';
import {MenuComponent} from './component/menu/menu.component';
import {AvailableComponent} from './component/filter/available/available.component';
import {InfoWindowComponent} from './component/info-window/info-window.component';
import {PowerComponent} from './component/filter/power/power.component';
import {AccessibilityComponent} from './component/filter/accessibility/accessibility.component';
import {DetailWindowComponent} from './component/detail-window/detail-window.component';
import {MatDialogModule} from '@angular/material/dialog';
import {
  BottomSheetAboutUsComponent, BottomSheetDisclaimerComponent, BottomSheetEmbedComponent,
  BottomSheetFAQComponent,
  FooterComponent
} from './component/footer/footer.component';
import {AppComponent} from './app.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {RootComponent} from './component/root/root.component';
import {routing, routingProviders} from './app.route';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {PriceWindowComponent} from './component/price-window/price-window.component';
import {BarChartModule} from '@swimlane/ngx-charts';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';


@NgModule({
  declarations: [
    AppComponent,
    MapComponent,
    MenuComponent,
    AvailableComponent,
    InfoWindowComponent,
    PowerComponent,
    AccessibilityComponent,
    DetailWindowComponent,
    FooterComponent,
    BottomSheetAboutUsComponent,
    BottomSheetFAQComponent,
    BottomSheetDisclaimerComponent,
    BottomSheetEmbedComponent,
    RootComponent,
    PriceWindowComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    MatToolbarModule,
    MatIconModule,
    MatExpansionModule,
    MatDividerModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatCardModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatDialogModule,
    MatBottomSheetModule,
    MatGridListModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatOptionModule,
    FlexLayoutModule,
    routingProviders,
    routing,
    TranslateModule
      .forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
        }
      }),
    BarChartModule,
    LeafletModule.forRoot()
  ],
  bootstrap: [RootComponent],
  entryComponents: [DetailWindowComponent, BottomSheetAboutUsComponent, BottomSheetFAQComponent, BottomSheetDisclaimerComponent,
    BottomSheetEmbedComponent, PriceWindowComponent, InfoWindowComponent]
})

export class AppModule {
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/brands/', '.json');
}
