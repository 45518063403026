import {Routes, RouterModule} from '@angular/router';
import {AppComponent} from './app.component';

export const routes: Routes = [
  {path: '', component: AppComponent},
  {path: ':brand', component: AppComponent},
  {path: 'widget/:width/:height/:ignore/:lat/:lng/:zoom', component: AppComponent},
  {path: 'widget/:width/:height/:ignore/:lat/:lng/:zoom/:ignore', component: AppComponent},
  {path: 'widget/:width/:height/:ignore/:lat/:lng/:zoom/:brand', component: AppComponent},
  {path: 'widget/:width/:height/:ignore/:lat/:lng/:zoom/:ignore/:brand', component: AppComponent}
];
export const routingProviders: any[] = [];
export const routing = RouterModule.forRoot(routes);
